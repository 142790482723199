<template>
	<el-dialog title="文件类型设定" :close-on-click-modal="false" :visible.sync="visible" width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="16">
					<el-form-item label="文件类型" prop="kindName">
						<el-input class="selItemInput" v-model="dataForm.kindName" placeholder="请输入" style="width:300px">
						</el-input>
					</el-form-item>

				</el-col>
				<el-col :span="8">
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">保存</el-button>
				</el-col>
			</el-row>
		</el-form>
		<el-row>
			<el-table ref="dataListTable" class="dataListTable" :data="dataList" header-cell-class-name="dataListTHeader"
				height="500" stripe header-align="center" style="width: 100%; max-height: 500px;">
				<el-table-column width="80" type="index" align="center">
				</el-table-column>
				<el-table-column prop="kindName" label="文件名称" align="center">
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-button v-preventReClick type="warning" size="small"
							@click="addOrUpdateHandle(scope.row.recId, scope.row.kindName)">修改
						</el-button>
						<el-button v-preventReClick type="danger" size="small" @click="deleteHandle(scope.row.recId)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-row>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">关闭</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "examination-add-or-update",
	data() {
		return {
			visible: false,
			inline: false,
			dataList: [],
			dataForm: {
				recId: "",
				kindName: "",
				kindType: "",
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
			},
			dataRule: {
				kindName: [
					{ required: true, message: "文件类型不能为空！", trigger: "blur" }
				],
			},
		}
	},
	methods: {
		init(type) {
			this.visible = true;
			this.dataForm = {
				recId: "",
				kindName: "",
				kindType: "",
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
			},
				this.dataForm.kindType = type ? type : 0;
			this.$nextTick(() => {
				if (this.dataForm.kindType) {
					this.$http({
						url: this.$store.state.httpUrl + '/business/filekindconfig/list',
						method: 'get',
						params: this.$http.adornParams({
							workNo: $common.getItem("workNo"),
							subjectNo: $common.getItem("subjectNo"),
							kindType: this.dataForm.kindType
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.dataList = data.body;
						}
					})
				}
			})
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/filekindconfig/save";
					if (this.dataForm.recId && this.dataForm.recId != 0) {
						_url = "/business/filekindconfig/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.dataForm
					}).then(({
						data
					}) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.init(this.dataForm.kindType);
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		},
		//删除
		deleteHandle(id) {
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + `/business/filekindconfig/delete/${id}`,
					method: "GET",
					params: {},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.init(this.dataForm.kindType)
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
		},
		addOrUpdateHandle(id, name) {
			this.dataForm.recId = id;
			this.dataForm.kindName = name;
		},
	}
}
</script>
<style scoped></style>
