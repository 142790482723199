<template>
	<!-- 巡视信息列表 -->
  <div class="mod-config">
	  <el-card body-style="padding-bottom: 0;">
		<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
			<el-row>
				<el-col :span="18">
						<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain >文件上传</el-button>
						<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain >文件模板</el-button>
						<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder" @click="fileTypeSetingHandle()">文件类型设定</el-button>
				</el-col>
				<el-col :span="6">
					<el-form-item label="文件名称" prop="nodeName">
						<el-input class="selItemInput" placeholder="文件名称" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	  </el-card>
	  <div :style="siteContentViewHeight()">
		  <el-card
			style="margin-top: 20px; height: 100%;"
			body-style="height: 100%; padding: 0;">
			  <el-row style="height: 100%;">
				  <el-col :span="2" style="background-color: #e9f4fb; height: 100%;">
					  <el-menu
							  :default-active="tabIndex"
							  class="tabMenu"
							  style="margin-top: 50px;">
						  <el-menu-item index="0" style="text-align: center;">
							  质量控制
						  </el-menu-item>
						  <el-menu-item index="1" style="text-align: center;">
							  安全管理
						  </el-menu-item>
						  <el-menu-item index="2" style="text-align: center;">
							  进度控制
						  </el-menu-item>
					  </el-menu>
				  </el-col>
				  <el-col :span="22" style="height: 100%;">
					  <el-table
					  ref="dataListTable"
						class="dataListTable"
						:data="dataList"
						stripe
						header-align="center"
						height="100%"
						style="width: 100%;"
						:row-class-name="tableRowClassName"
						header-cell-class-name="dataListTHeader"
						@selection-change="handleSelectionChange">
						<el-table-column
							type="selection"
							width="60"
							align="center">
						</el-table-column>
						<el-table-column
						  prop="name"
						  label="文件名称"
						  width="150"
						  align="center">
						</el-table-column>
						<el-table-column
						  prop="name"
						  label="创建人"
						  width="120"
						  align="center">
						</el-table-column>
						  <el-table-column
								  prop="time"
								  label="上传时间 "
								  width="150"
								  align="center">
						  </el-table-column>
						<el-table-column
						  prop="describe"
						  label="状态"
						  width="120"
						  align="center">
						</el-table-column>
						<el-table-column
						  prop="describe"
						  label="是否归档"
						  width="120"
						  align="center">
						</el-table-column>
						<el-table-column
						  prop="describe"
						  label="归档标识"
						  align="center">
						</el-table-column>
						<el-table-column
						  label="操作"
						  width="400"
						  align="center">
						  <template slot-scope="scope">
							  <el-button v-preventReClick type="primary" size="small">查看</el-button>
							  <el-button v-preventReClick type="danger" size="small">修改</el-button>
							  <el-button v-preventReClick type="primary" size="small">归档</el-button>
							  <el-button v-preventReClick type="danger" size="small">删除</el-button>
						  </template>
						</el-table-column>
					  </el-table>
				  </el-col>
			  </el-row>
		  </el-card>
	  </div>
	  <file-type-seting v-if="fileTypeSetingVisible" ref="fileTypeSeting" @refreshDataList="getDocumentTypeList()"></file-type-seting>
<!--	  <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>-->
  </div>
</template>
<script>
// import AddOrUpdate from './patrolList-add-or-update'
import FileTypeSeting from './file-type-seting'
import $common from "@/utils/common.js"
export default {
  data() {
    return {
      tabIndex: "0",
	  fileTypeSetingVisible: false,
	  addOrUpdateVisible: false,
	  form:{},
	  category:0,
	  dataList:[],
	  tableSelVal:[],
	  tableSelIdArray:[],
	  selAllFlg: false,
	  poseTime:[],
	  closeTime:[],
	  //当前页码
	  pageIndex: 1,
	  //当前记录数
	  pageSize: 10,
	  //总页数
	  totalPage: 0,
    };
  },
  components: {
	// AddOrUpdate
	FileTypeSeting
  },
  mounted() {
  },
  methods: {
	siteContentViewHeight () {
	  var height = this.$store.state.common.documentClientHeight - 200;
	  return { height: height + 'px' }
	},
	handleSelectionChange(selection,item) {
		// this.tableSelVal = selection;
		// let _tableSelIdArray = [];
		// selection.forEach((item) => {
		// 	_tableSelIdArray.push(item.probId)
		// })
		// this.tableSelIdArray = _tableSelIdArray;
		// if(selection.length == this.dataList.length){
		// 	this.selAllFlg = true;
		// }else{
		// 	this.selAllFlg = false;
		// }
	},
	tableRowClassName({ row, rowIndex }) {
	  let color = ''
	  for(let item of this.tableSelVal){
		if(item.id === row.id)color = 'table-SelectedRow-bgcolor'
	  }
	  return color
	},
	addOrUpdateHandle(id,flg){
		// this.addOrUpdateVisible = true
		// this.$nextTick(() => {
		//   this.$refs.addOrUpdate.init(id,flg)
		// })
	},
	setCategory(value){
		this.category = value;
		this.pageIndex = 1;
		// this.getDataList();
	},
	selGetDataList(){
		this.pageIndex = 1;
		// this.getDataList();
	},
	currentChangeHandle(val) {
		this.pageIndex = val
		// this.getDataList()
	},
	toggleAllSelection() {
		this.$refs.dataListTable.toggleAllSelection();
	},
	resetFormData(){
		this.subjectNo = "";
		this.major = "";
		this.poseTime = [];
	},
	fileTypeSetingHandle(){
		this.fileTypeSetingVisible = true
		this.$nextTick(() => {
		  this.$refs.fileTypeSeting.init('3')
		})
	},
  }
};
</script>
<style lang="scss" scoped="scoped">
	.selItemText{
		font-size: 14px;
		color: #1c1c1c;
		display: inline-block;
		width: 80px;
	}
</style>
